<template>
  <div class="ra-basic ra-basic-text">
    <p>
      My Alter ego - <b>Takeshi Lev Kovacs</b>
    </p>
    <img
      src="@/assets/alterego/arena.jpg"
      class="ra-img-arena"
    >
    <p>
      Hero of the book triology
      <RaLink
        link="https://en.wikipedia.org/wiki/Altered_Carbon"
        styled
        v-text="'Altered Carbon'"
      />
      by <b>Richard Morgan</b>
      <br />
      Protagonist of the movies and cartoon on <b>Netflix</b><br />
      Bellow some fragments from them
    </p>
    <p>
      Takeshi appears to be a person of strict loyalty to both his family,
      people, and organizations- in that,
      he is loyal to a person/organization who has earned his loyalty,
      but he has no qualms in breaking that loyalty if the person/organization
      in question breaks that loyalty.
      A prime example is the CTAC.
      Takeshi was loyal to this organization for many years
      in the understanding that
      they had looked after his sister by placing her with a loving family.
      When he realized they had sold her to the Yakuza,
      his loyalty was shattered,
      and his family loyalty towards his sister took precedence.
    </p>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/rGl5RGVXR0w"
      frameborder="0"
      allow="
        accelerometer;
        autoplay;
        clipboard-write;
        encrypted-media;
        gyroscope;
        picture-in-picture
      "
      allowfullscreen
    />
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/YkPaV-nsGrY"
      frameborder="0"
      allow="
        accelerometer;
        autoplay;
        clipboard-write;
        encrypted-media;
        gyroscope;
        picture-in-picture
      "
      allowfullscreen
    />
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/pp3DSWz_GyU"
      frameborder="0"
      allow="
        accelerometer;
        autoplay;
        clipboard-write;
        encrypted-media;
        gyroscope;
        picture-in-picture
      "
      allowfullscreen
    />
  </div>
</template>

<script>
import RaLink from '@/components/elements/RaLink.vue'

export default {
  name: 'Alterego',

  metaInfo: {
    title: 'Alter ego',
    titleTemplate: '%s | Roman Almazov'
  },

  components: {
    RaLink
  }
}
</script>

<style lang="scss" scoped>
  .ra-img-arena {
    width: 100%;
    max-width: 600px;
  }
</style>
